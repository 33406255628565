::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: black;
}

.tableFooter {
  padding: 0px 0px !important;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.button {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.activeButton {
  color: white;
  background: #185adb;
}

.inactiveButton {
  color: #2c3e50;
  background: #f9f9f9;
}

.css-1scuwf1 {
  padding: 0px 0px !important;
}
